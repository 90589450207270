import { apiHeader } from "./BaseUrl";

// Auth - Login
export const loginUser = async (values) => {
  return handleResponse(
    apiHeader.post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      device_type: "web",
    })
  );
};

// Auth - Logout
export const logoutUser = async () => {
  const access_token = localStorage.getItem("token") || {};
  return handleResponse(
    apiHeader.post("/auth/logout", {
      access_token: access_token,
      device_token: "string",
    })
  );
};

// Auth - Forgot Password
export const forgotPassword = async (values) => {
  return handleResponse(
    apiHeader.post("/auth/forgotpwd", {
      email: values.email,
    })
  );
};

//Auth Refresh Token
export const refreshAccessToken = async (refreshToken) => {
  return handleResponse(
    apiHeader.post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
  );
};

// Auth - Reset Password Api
export const resetPassword = async (values, token) => {
  return handleResponse(
    apiHeader.post("/auth/forgotpwd/reset", {
      email: values.email,
      forgot_pwd_token: token,
      new_password: values.new_password,
      confirm_new_password: values.confirm_password,
    })
  );
};

// Dashboard Card States
export const dashboardStates = async () => {
  return handleResponse(apiHeader.get("/dashboard"));
};

export const cmsGet = async (limit, offset, search, type) => {
  return handleResponse(
    apiHeader.get(`/cms/${type}/?limit=${limit}&offset=${offset}`)
  );
};

export const cmsGetFaqs = async (limit, offset, search, type, id) => {
  return handleResponse(
    apiHeader.get(`/cms/${type}?type_id=${id}&limit=${limit}&offset=${offset}`)
  );
};

export const cmsCreate = async (type, values, id) => {
  let requestBody = {
    title: values.title,
    description: values.description,
  };

  // Add `child: true` if type is 'privacy-policys'
  if (type === "privacy-policys") {
    requestBody.child = true;
  } else if (type === "faqs") {
    requestBody.type_id = id;
  }

  return handleResponse(apiHeader.post(`/cms/${type}`, requestBody));
};

export const FaqsTypeCreate = async (values) => {
  return handleResponse(apiHeader.post(`/cms/faq-types`, values));
};

export const FaqsTypeUpdate = async (values, id) => {
  return handleResponse(apiHeader.put(`/cms/faq-types/${id}`, values));
};

export const cmsUpdate = async (type, values, id) => {
  let requestBody = {
    title: values.title,
    description: values.description,
  };

  // Add `child: true` if type is 'privacy-policys'
  if (type === "privacy-policys") {
    requestBody.child = true;
  }

  return handleResponse(apiHeader.put(`/cms/${type}/${id}`, requestBody));
};

export const cmsUpdatePolicy = async (type, values, id) => {
  return handleResponse(
    apiHeader.put(`/cms/${type}/${id}`, {
      title: "",
      description: values.description,
      child: false,
    })
  );
};

export const cmsDelete = async (type, id) => {
  return handleResponse(apiHeader.delete(`/cms/${type}/${id}`));
};

export const cmsStatusUpdate = async (type, id, statusActive) => {
  return handleResponse(
    apiHeader.patch(`/cms/${type}/${id}/status`, {
      active: statusActive,
    })
  );
};

export const cmsSwap = async (type, new_value, new_id) => {
  return handleResponse(
    apiHeader.patch(`/cms/${type}/sort-order`, {
      new_value: new_value,
      new_id: new_id,
    })
  );
};

export const cmsSwapFaqs = async (type, new_value, new_id, id) => {
  return handleResponse(
    apiHeader.patch(`/cms/${type}/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      type_id: id,
    })
  );
};

// Product Info - Get
export const productInfoGet = async () => {
  return handleResponse(apiHeader.get(`/cms/product-info`));
};

// Product Info - Update
export const productInfoUpdate = async (values, id) => {
  return handleResponse(
    apiHeader.put(`/cms/product-info/${id}`, {
      how_to_order: values?.how_to_order,
      shipping_detail: values?.shipping_details,
      price_match_guarantee: values?.price_match_guarantee,
      return_cancellation: values?.return_cancellations,
    })
  );
};

// ArtWork - Update
export const artWorkGet = async () => {
  return handleResponse(apiHeader.get(`/cms/artwork`));
};

// ArtWork - Update
export const artWorkUpdate = async (values, type, id) => {
  let requestBody;
  if (type == "one") {
    requestBody = {
      section: type,
      title_one: values.title,
      description_one: values.description,
    };
  } else if (type == "two") {
    requestBody = {
      section: type,
      title_two: values.title,
      description_two: values.description,
    };
  } else if (type == "three") {
    requestBody = {
      section: type,
      title_three: values.title,
      description_three: values.description,
    };
  } else {
    requestBody = {
      section: type,
      column_one_title: values?.column_1_title,
      column_one_sub_title: values?.column_1_subtitle,
      column_one_file_extension: values?.editor_1,
      column_one_message: values?.column_1_message,
      column_two_title: values?.column_2_title,
      column_two_sub_title: values?.column_2_subtitle,
      column_two_file_extension: values?.editor_2,
      column_two_message: values?.column_2_message,
      column_three_title: values?.column_3_title,
      column_three_sub_title: values?.column_3_subtitle,
      column_three_file_extension: values?.editor_3,
      column_three_message: values?.column_3_message,
      note: values?.note,
    };
  }

  return handleResponse(apiHeader.put(`cms/artwork/${id}`, requestBody));
};

export const uploadartImage = async (image, id) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`cms/artwork/${id}/img`, formData));
};

// Settings - User Profile Get Data
export const getProfile = async (values) => {
  return handleResponse(apiHeader.get(`/user`));
};

// Settings - User Profile Change Password
export const changePassword = async (values) => {
  return handleResponse(
    apiHeader.put("/auth/password", {
      email: values.email,
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    })
  );
};

// Settings - User Profile Edit Data
export const editProfile = async (values) => {
  return handleResponse(
    apiHeader.put("/user", {
      first_name: values.first_name,
      last_name: values.last_name,
      slug: values.phone.slug,
      country_code: values.phone.code,
      number: values.phone.number,
    })
  );
};

// Settings - User Profile Edit Image
export const uploadProfileImage = async (image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/user/img`, formData));
};

// Products
export const getAllProducts = async (
  limit,
  offset,
  supplierId,
  styleCode,
  styleName,
  categoryId,
  subCategoryId,
  brand,
  published
) => {
  let queryParams = `limit=${limit}&offset=${offset}&supplier_id=${supplierId}&published=${published}`;
  if (categoryId) queryParams += `&category_id=${categoryId}`;
  if (subCategoryId) queryParams += `&subcategory_id=${subCategoryId}`;
  if (styleCode) queryParams += `&style_code=${styleCode}`;
  if (styleName) queryParams += `&style_name=${styleName}`;
  if (brand) queryParams += `&brand=${brand}`;

  return handleResponse(apiHeader.get(`/products/?${queryParams}`));
};

// export const getProductById = async (id) => {
//   return handleResponse(apiHeader.post(`/products/variants/?sku=${id}`));
// };

// export const getProductById = async (id) => {
//   return handleResponse(apiHeader.get(`/products/${id}`));
// };

export const getProductById = async (sku, brand, supplier_id) => {
  return handleResponse(apiHeader.post(`/products/detail`, {
    sku,
    brand,
    supplier_id
  }));
};

// Category List
export const getCategoriesList = async () => {
  return handleResponse(apiHeader.get(`/categories/list`));
};

export const productStatusUpdate = async (id, status, supplier_id, brand) => {
  return handleResponse(
    apiHeader.patch(`/products/${id}/status`, { published: !status , supplier_id, brand })
  );
};

export const trendingStatusUpdate = async (id, status, supplier_id, brand) => {
  return handleResponse(
    apiHeader.patch(`/products/${id}/trending`, { trending: !status , supplier_id, brand })
  );
};

// export const variant = async (color, size, sku) => {
//   let query = `/products/variants/?sku=${sku}`;

//   if (color) {
//     query += `&color=${color}`;
//   }

//   if (size) {
//     query += `&size=${size}`;
//   }

//   return handleResponse(apiHeader.get(query));
// };

export const variant = async (color, size, sku, brand, supplier_id) => {
  let query = `/products/variants`;

  const payload = {
    color,
    size,
    sku,
    brand,
    supplier_id
  };

  return handleResponse(apiHeader.post(query, payload));
};


// Categories
export const categories = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/categories/?${queryParams}`));
};

export const getCategoryById = async (id) => {
  return handleResponse(apiHeader.get(`/categories/${id}`));
};

export const createCategory = async (values) => {
  return handleResponse(apiHeader.post("/categories", values));
};

export const uploadCategoryImage = async (id, type, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(
    apiHeader.post(`/categories/${id}/img/${type}`, formData)
  );
};

export const updateCategory = async (id, values) => {
  return handleResponse(apiHeader.put(`/categories/${id}`, values));
};

export const categoriesStatusUpdate = async (id, status) => {
  return handleResponse(
    apiHeader.patch(`/categories/${id}/status`, { active: !status })
  );
};

// Sub Categories
export const subCategories = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;

  return handleResponse(apiHeader.get(`/sub-categories/?${queryParams}`));
};

export const getSubCategoryById = async (id) => {
  return handleResponse(apiHeader.get(`/sub-categories/${id}`));
};

export const createSubCategory = async (values) => {
  return handleResponse(apiHeader.post("/sub-categories", values));
};

export const uploadSubCategoryImage = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/sub-categories/${id}/img`, formData));
};

export const updateSubCategory = async (id, values) => {
  return handleResponse(apiHeader.put(`/sub-categories/${id}`, values));
};

export const updateSubCategoryStatus = async (id, status) => {
  return handleResponse(
    apiHeader.patch(`/sub-categories/${id}/status`, { active: !status })
  );
};

// Banners
export const banners = async (limit, offset) => {
  return handleResponse(
    apiHeader.get(`/banners/?limit=${limit}&offset=${offset}`)
  );
};

// Banners - Create
export const createBanner = async (values) => {
  return handleResponse(
    apiHeader.post(`/banners`, {
      url: values?.url,
    })
  );
};

// Banners - Update
export const updateBanner = async (id, values) => {
  return handleResponse(
    apiHeader.put(`/banners/${id}`, {
      url: values?.url,
    })
  );
};

// Banners - Update Status
export const updateBannerStatus = async (id, statusActive) => {
  return handleResponse(
    apiHeader.patch(`/banners/${id}/status`, {
      active: statusActive,
    })
  );
};

// Banners - Delete
export const deleteBanner = async (id) => {
  return handleResponse(apiHeader.delete(`/banners/${id}`));
};

// Banners - Swap
export const swapBanners = async (new_value, new_id) => {
  return handleResponse(
    apiHeader.patch(`/banners/sort-order`, {
      new_value: new_value,
      new_id: new_id,
    })
  );
};

// Banners - Upload Image
export const uploadBannerImage = async (id, image, type) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/banners/${id}/img/${type}`, formData));
};

// Settings - Get
export const getSettings = async (type) => {
  return handleResponse(apiHeader.get(`/setting/${type}`));
};

// Settings - Update
export const updateSettings = async (type, values) => {
  return handleResponse(apiHeader.put(`/setting/${type}`, values));
};

// Contact Us
export const contactUs = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search}`;

  return handleResponse(apiHeader.get(`/contact-us/?${queryParams}`));
};

// Update Customization Tabs
export const updateCustomization = async (type, id, values) => {
  return handleResponse(apiHeader.put(`/cms/${type}/${id}`, values));
};

export const updateAboutUs = async (id, values) => {
  return handleResponse(apiHeader.put(`/cms/about-us/${id}`, values));
};

export const updateAboutUsImg = async (id, type, image) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("type", type);

  return handleResponse(apiHeader.post(`/cms/about-us/${id}/img`, formData));
};

// Manage Home - Child List
export const getHomeChid = async (type, limit, offset) => {
  return handleResponse(
    apiHeader.get(
      `/cms/home/child/?limit=${limit}&offset=${offset}&type=${type}`
    )
  );
};

// Manage Home - Update
export const updateHome = async (id, values) => {
  return handleResponse(apiHeader.put(`/cms/home/${id}`, values));
};

// Create Home Child
export const createHomeChild = async (values) => {
  return handleResponse(apiHeader.post(`/cms/home/child`, values));
};

// Update Home Child
export const updateHomeChild = async (id, values) => {
  return handleResponse(apiHeader.put(`/cms/home/child/${id}`, values));
};

// Delete Home Child
export const deleteHomeChild = async (id) => {
  return handleResponse(apiHeader.delete(`/cms/home/child/${id}`));
};

export const updateHomeImg = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/cms/home/${id}/img`, formData));
};

// Update Home Child Img
export const updateHomeChildImg = async (id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(apiHeader.post(`/cms/home/child/${id}/img`, formData));
};

// Delete Home Image
export const deleteHomeImg = async (id, img_name) => {
  return handleResponse(apiHeader.delete(`/cms/home/${id}/img/${img_name}`));
};

// Suppliers
export const getSuppliers = async (limit, offset) => {
  return handleResponse(
    apiHeader.get(`/suppliers/?limit=${limit}&offset=${offset}`)
  );
};

export const getSupplierById = async (id) => {
  return handleResponse(apiHeader.get(`/suppliers/${id}`));
};

export const getSupplierCategories = async (supplier_id) => {
  return handleResponse(apiHeader.get(`/suppliers/${supplier_id}/categories`));
};

export const getSupplierFileLogs = async (supplier_id) => {
  return handleResponse(apiHeader.get(`/suppliers/${supplier_id}/files`));
};

export const postSupplierCategories = async (supplier_id, categories) => {
  return handleResponse(apiHeader.post(`/suppliers/categories/map`, {supplier_id, categories}));
};

export const updateSupplier = async (supplier_id, values) => {
  return handleResponse(apiHeader.put(`/suppliers/${supplier_id}`, values));
};

export const updateSupplierImg = async (supplier_id, image) => {
  const formData = new FormData();
  formData.append("image", image);

  return handleResponse(
    apiHeader.post(`/suppliers/${supplier_id}/img`, formData)
  );
};

export const updateSupplierProducts = async (supplier_id, file) => {
  const formData = new FormData();
  formData.append("supplier_id", supplier_id);
  formData.append("file", file);

  return handleResponse(apiHeader.post(`/suppliers/files/upload`, formData));
};

// Category Linking
export const mapCategories = async (published, file) => {
  const formData = new FormData();
  formData.append("published", published);
  formData.append("file", file);

  return handleResponse(apiHeader.post(`/categories/mapping/upload`, formData));
};

export const getCategoryFileLogs = async () => {
  return handleResponse(apiHeader.get(`/categories/mapping/files`));
};

// Get Sample File
export const getSampleFile = async () => {
  return handleResponse(apiHeader.get(`/categories/mapping/simple-file`));
};

// Reusable Response handler
export const handleResponse = async (apiCall) => {
  let data;
  await apiCall
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};



//Markup Percentage
export const getAllMarkup = async (supplier_id) => {
  return handleResponse(apiHeader.get(`/markup/${supplier_id}`));
};


// Markup Percentage Details
export const getMarkupById = async (markup_id) => {
  return handleResponse(apiHeader.get(`/markup/details/${markup_id}`));
};

// Markup Delete
export const deleteMarkup = async (markup_id) => {
  return handleResponse(apiHeader.delete(`/markup/${markup_id}`));
};

// Get Markup Export
export const getMarkupFile = async (supplier_id) => {
  return handleResponse(apiHeader.get(`/markup/export/${supplier_id}`));
};

// Import Markup File
export const mapMarkupFile = async (file, supplier_id) => {
  const formData = new FormData();
  // formData.append("published", published);
  formData.append("file", file);

  return handleResponse(apiHeader.post(`/markup/import/${supplier_id}`, formData));
};

// Create Markup
export const createMarkup = async (values) => {
  return handleResponse(apiHeader.post("/markup", values));
};

// Edit Markup
export const updateMarkup = async (markup_id, values) => {
  return handleResponse(apiHeader.put(`/markup/${markup_id}`, values));
};

// Edit Listing Price
export const updatePrice = async (group_field, values) => {
  return handleResponse(apiHeader.patch(`/products/${group_field}/price`, values));
};

//Edit Variant Price
export const updateVariantPrice = async (group_field, values) => {
  return handleResponse(apiHeader.patch(`/products/${group_field}/variants/price`, values));
};

// Vat Settings
export const getVat = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/vat/?${queryParams}`));
};

//Vat Create
export const createVat = async (values) => {
  return handleResponse(apiHeader.post("/vat", values));
};

// Edit Vat
export const updateVat = async (id , values) => {
  return handleResponse(apiHeader.put(`/vat/${id }`, values));
};

//Vat States
export const getStates = async () => {
  return handleResponse(apiHeader.get(`/states`));
};



// Stitch Management Get
export const getStitch = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/stitchs/?${queryParams}`));
};

// Create Stitch
export const createStitch = async (values) => {
  return handleResponse(apiHeader.post("/stitchs", values));
};

// Edit Stitch
export const updateStitch = async (stitch_id, values) => {
  return handleResponse(apiHeader.put(`/stitchs/${stitch_id}`, values));
};

// Stitch Details
export const getStitchById = async (stitch_id ) => {
  return handleResponse(apiHeader.get(`/stitchs/${stitch_id }`));
};

// Update Stitch Status
export const updateStitchStatus = async (stitch_id , status) => {
  return handleResponse(
    apiHeader.patch(`/stitchs/${stitch_id }/status`, { active: !status })
  );
};

// Location Management Get
export const getLocation = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/locations/?${queryParams}`));
};

// Create Location
export const createLocation = async (values) => {
  return handleResponse(apiHeader.post("/locations", values));
};


// Edit Location
export const updateLocation = async (location_id , values) => {
  return handleResponse(apiHeader.put(`/locations/${location_id }`, values));
};

// Update Stitch Status
export const updateLocationStatus = async (location_id , status) => {
  return handleResponse(
    apiHeader.patch(`/locations/${location_id }/status`, { active: !status })
  );
};


// Color Management Get
export const getColor = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/colors/?${queryParams}`));
};

// Create Color
export const createColor = async (values) => {
  return handleResponse(apiHeader.post("/colors", values));
};

// Edit Color
export const updateColor = async (color_id , values) => {
  return handleResponse(apiHeader.put(`/colors/${color_id }`, values));
};

// Update Color Status
export const updateColorStatus = async (color_id , status) => {
  return handleResponse(
    apiHeader.patch(`/colors/${color_id }/status`, { active: !status })
  );
};


//Printing Modules Get
export const getPrintingModule = async () => {
  return handleResponse(apiHeader.get(`/printing-module/modules/list`));
};


// Print Size Get
export const getPrintSize = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/print-sizes/?${queryParams}`));
};

// Create PrintSize
export const createPrintSize = async (values) => {
  return handleResponse(apiHeader.post("/print-sizes", values));
};

// Edit PrintSize
export const updatePrintSize = async (print_id , values) => {
  return handleResponse(apiHeader.put(`/print-sizes/${print_id }`, values));
};

// Update PrintSize Status
export const updatePrintSizeStatus = async (print_id , status) => {
  return handleResponse(
    apiHeader.patch(`/print-sizes/${print_id }/status`, { active: !status })
  );
};

// Vinyl Print Get
export const getVinylPrint = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/vinyl-print/?${queryParams}`));
};

// Create Vinyl Print
export const createVinylPrint = async (values) => {
  return handleResponse(apiHeader.post("/vinyl-print", values));
};

// Edit PrintSize
export const updateVinylPrint = async (print_id , values) => {
  return handleResponse(apiHeader.put(`/vinyl-print/${print_id }`, values));
};

// Update VinylPrint Status
export const updateVinylPrintStatus = async (print_id , status) => {
  return handleResponse(
    apiHeader.patch(`/vinyl-print/${print_id }/status`, { active: !status })
  );
};

// Clip Art Categories Get
export const clipartCategories = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/clip-art-category/?${queryParams}`));
};

// Create Clip Art Categories
export const createClipArtCategory = async (values) => {
  return handleResponse(apiHeader.post("/clip-art-category", values));
};

// Edit Clip Art Categories
export const updateClipArtCategory = async (id, values) => {
  return handleResponse(apiHeader.put(`/clip-art-category/${id}`, values));
};

// Update ClipArt Category Status
export const updateClipArtCatStatus = async (category_id , status) => {
  return handleResponse(
    apiHeader.patch(`/clip-art-category/${category_id }/status`, { active: !status })
  );
};


// Clip Art Sub Categories Get
export const clipartSubCategories = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/clip-art-subcategory/?${queryParams}`));
};

// Clip Art Sub Categories Get--Parent Categories
export const getParentCategory = async () => {
  return handleResponse(apiHeader.get(`/clip-art-subcategory/categories`));
};

// Create Clip Art Sub-Categories
export const createClipArtSubCategory = async (values) => {
  return handleResponse(apiHeader.post("/clip-art-subcategory", values));
};

// Edit Clip Art Categories
export const updateClipArtSub = async (id, values) => {
  return handleResponse(apiHeader.put(`/clip-art-subcategory/${id}`, values));
};

// Update ClipArt Sub-Category Status
export const updateClipArtSubStatus = async (category_id , status) => {
  return handleResponse(
    apiHeader.patch(`/clip-art-subcategory/${category_id }/status`, { active: !status })
  );
};

// Clip Art Images Get
export const clipartImages = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/clip-art-images/?${queryParams}`));
};

// Update ClipArt Sub-Category Status
export const updateClipImageStatus = async (category_id , status) => {
  return handleResponse(
    apiHeader.patch(`/clip-art-images/${category_id }/status`, { active: !status })
  );
};

// Clip Art Images--SubCategories-- Get
export const clipImagesSubCategories = async (category_id ) => {
  return handleResponse(apiHeader.get(`/clip-art-images/subcategories/${category_id}`));
};

// Create Clip Art Image
export const createClipArtImages = async (values) => {
  return handleResponse(apiHeader.post("/clip-art-images", values));
};

// Edit Clip Art Image
export const updateClipArImages = async (id, values) => {
  return handleResponse(apiHeader.put(`/clip-art-images/${id}`, values));
};

// Upload Clip Art Images
export const uploadClipArtImg = async (clip_art_id, images) => {
  const formData = new FormData();

  // Use forEach to append all images at once
  images?.forEach((image) => formData.append("images", image));

  // Make the API request
  return handleResponse(apiHeader.post(`/clip-art-images/${clip_art_id}/img/`, formData));
};

// Upload Clip Art Images
// export const uploadClipArtImg = async (clip_art_id, img) => {
//   const formData = new FormData();
//   formData.append("image", img);

//   return handleResponse(
//     apiHeader.post(`/clip-art-images/${clip_art_id }/img`, formData)
//   );
// };

//Edit Clip Art Images
// Upload Clip Art Images
export const editClipArtImg = async (clip_art_id, images) => {
  const formData = new FormData();

  // Iterate over all images and append to FormData
  images?.forEach((image) => {
    if (image instanceof File) {
      formData.append("images", image); // For new images (binary)
    } else if (typeof image === "string" && image.startsWith("http")) {
      // Assuming it's an image URL, you can send it as it is
      // If you need to convert URLs to Blobs, you'd fetch them here (optional)
      formData.append("images", image); // Add existing image URL
    }
  });

  // Make the API request
  return handleResponse(apiHeader.post(`/clip-art-images/${clip_art_id}/img/`, formData));
};

// Delete Clip Art Image
export const deleteClipImg = async (clip_art_id , image_name ) => {
  return handleResponse(apiHeader.delete(`/clip-art-images/${clip_art_id }/images/${image_name }`));
};


// Upload Image
// export const uploadClipArtImg = async (clip_art_id, img) => {
//   const data = new FormData();
//  img?.map((img =>  data.append("images", img)));
//   return handleResponse(apiHeader.post(`/clip-art-images/${clip_art_id}/img/`, data));
// };

// Style Font
export const getStyleFont = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/style-fonts/?${queryParams}`));
};


// Create Style Font
// export const createStyleFont = async (values) => {
//   return handleResponse(apiHeader.post("/style-fonts", values));
// };

// Upload Font Files
export const addFontFile = async (files) => {
  const formData = new FormData();

  // Ensure files array is not empty before appending
  if (files && files.length > 0) {
    files.forEach((file) => formData.append("docs", file)); // 'docs' is the key expected by the server
  } else {
    throw new Error("At least one file is required.");
  }

  // Make the API request
  return handleResponse(apiHeader.post(`/style-fonts/uploads`, formData));
};

// Edit-Upload Font File
export const uploadFontFile = async (style_id , file) => {
  const formData = new FormData();
  // formData.append("published", published);
  formData.append("doc", file);

  return handleResponse(apiHeader.post(`/style-fonts/${style_id}/upload`, formData));
};

// Update Style Font Status
export const updateFontStatus = async (style_id , status) => {
  return handleResponse(
    apiHeader.patch(`/style-fonts/${style_id }/status`, { active: !status })
  );
};

// Edit Font Style
// export const updateStyleFont = async (style_id , values) => {
//   return handleResponse(apiHeader.put(`/style-fonts/${style_id }`, values));
// };



// Customization Pricing
export const getModuleList = async () => {
  return handleResponse(apiHeader.get(`/printing-module/modules/list`));
};

// Customization Pricing
export const updatePritingQuantity = async (module_id , values) => {
  return handleResponse(apiHeader.put(`/printing-module/module-details/${module_id}`, values));
};

// Printing Module--Stitches
export const getStitchList = async () => {
  return handleResponse(apiHeader.get(`/printing-module/stitches/list`));
};

// Printing Module--Location
export const getLocationList = async (module_id) => {
  return handleResponse(apiHeader.get(`/printing-module/${module_id}/locations/list`));
};

// Printing Module--Print Size
export const getPrintSizeList = async () => {
  return handleResponse(apiHeader.get(`/printing-module/print-sizes/list`));
};

// Printing Module--Color
export const getColorList = async () => {
  return handleResponse(apiHeader.get(`/printing-module/colors/list`));
};

// Printing Module--Vinyl Printing
export const getVinylList = async () => {
  return handleResponse(apiHeader.get(`/printing-module/vinyl-printing/list`));
};

// Printing Module--Get Table Info
export const getModule = async (module_id) => {
  return handleResponse(apiHeader.get(`/printing-module/${module_id}`));
};

// Printing Module--Post Table Info
export const createModule = async (values) => {
  return handleResponse(apiHeader.post(`/printing-module`, values));
};

// Update Pricing Module Location
export const updateModuleLocations = async (id, location_ids) => {
  return handleResponse(apiHeader.patch(`/printing-module/${id}/variant`, {location_ids}));
};

// Update Pricing Module Location
export const updateModule = async (values) => {
  return handleResponse(apiHeader.put(`/printing-module/update/pricing`, values));
};

// Delete Module Pricing
export const deleteModulePricing = async (values) => {
  return handleResponse(apiHeader.delete(`/printing-module/delete/pricing`, {data:values}));
};

// User Management
export const getPersonalUser = async (limit, offset, search, userType, status) => {
  let queryParams = `limit=${limit}&offset=${offset}&type=${userType}&status=${status}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/customers/?${queryParams}`));
};

// Update Customer Status
export const updateCustomerStatus = async (id, status) => {
  return handleResponse(
    apiHeader.patch(`/customers/${id}/status`, { active: !status })
  );
};

//Update Customer-NativeCard Status
export const updateNativeStatus = async (id, value) => {
  return handleResponse(
    apiHeader.patch(`/customers/native-card/${id}/status`, { status: value.status, message: value.reason })
  );
};

// Export Customer
export const getCustomerFile = async () => {
  return handleResponse(apiHeader.get(`/customers/export`));
};

// Promo Codes -- Get
export const getPromoCodes = async (limit, offset, search) => {
  let queryParams = `limit=${limit}&offset=${offset}`;
  if (search) queryParams += `&search=${search?.replace('&', '%26')}`;

  return handleResponse(apiHeader.get(`/promo-code/?${queryParams}`));
};

export const updatePromoStatus = async (color_id , status) => {
  return handleResponse(
    apiHeader.patch(`/promo-code/${color_id }/status`, { active: !status })
  );
};


// Promo Codes -- Posts
export const createPromo = async (values ) => {
  return handleResponse(apiHeader.post("/promo-code", values));
};


// Promo Codes -- EDIT
export const updatePromoCode = async (_id , values) => {
  return handleResponse(apiHeader.put(`/promo-code/${_id }`, values));
};